import styled from "styled-components"
import theme from "src/theme/"
import Text from "src/components/text/text.component"
import Column, { BoxProps as ColumnProps } from "src/components/column/column.component"
import Row from "src/components/row/row.component"
import Button from "src/components/button/button.component"
import Box from "src/components/box/box.component"
import Select from "src/components/select/select.component"
import Image1 from "./find.svg"
import Image2 from "./connect.svg"
import Image3 from "./complete.svg"
import ProgressiveImage from "react-progressive-bg-image"

export const HomeWrapper = styled(Column as any)`
  align-items: center;
  justify-content: center;
  color: ${theme.colors.primary};
  overflow-y: auto;
  overflow-x: hidden;
`

export const MissionItems = styled(Row as any)`
  justify-content: space-between;
  flex-wrap: wrap;
  @media (min-width: ${theme.breakpoints[0]}) {
  }
`

export const MissionItem = styled(Column as any)`
  max-width: 320px;
`

export const PageSection = styled(Column as any)`
  justify-content: space-between;
  width: 100%;
  max-width: 1184px;
  margin-top: 64px;
  @media (max-width: ${theme.breakpoints[0]}) {
    margin-top: 112px;
  }
`

export const SectionIcon = styled.div<any>`
  display: flex;
  width: 64px;
  height: 64px;
  background-size: fit;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
`

export const ListingItems = styled(Row as any)`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const ListingItem = styled(Column as any)`
  cursor: pointer;
  width: ${props => props.width};
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: ${props => props.marginBottom ?? "64px"};
  &:nth-child(odd) {
    padding-left: 0;
  }
  &:nth-child(even) {
    padding-right: 0;
  }
  @media (min-width: ${theme.breakpoints[0]}) {
    padding-left: 0;
    padding-right: 24px;
    &:nth-child(odd) {
      padding-left: 0;
    }
    &:nth-child(even) {
      padding-right: 24px;
    }
  }
`

export const ListingItemImage = styled(ProgressiveImage)`
  display:flex;
  flex-direction:column;
  width:100%;
  height:112px;
  border-radius: 4px;
  background-color: #d8d8d8;
  background-size:cover;
  background-position:center;
  background-image:url('${props => props.src}');
  @media (min-width:${theme.breakpoints[0]}) {
    height:172px;
  }
`

export const SeeMoreListings = styled(Column as any) <ColumnProps & { src: string }>`
  align-items:center;
  width:${props => props.width};
  height:112px;
  max-width:100%;
  margin-right:0;
  border-radius: 4px;
  background-size:cover;
  background-position:center;
  background-image:url('${props => props.src}');
  cursor:pointer;
  @media (min-width:${theme.breakpoints[0]}) {
    height:172px;
    margin-right:18px;
  }
  &:hover {
    >div:nth-of-type(2) {
      background-color: orange;
      background-color: rgba(0, 168, 87, 0.85);
    }
  }
`

export const SeeMoreListingsOverlay = styled(Column as any)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 168, 87, 0.7);
  border-radius: 4px;
  z-index: 1;
  transition-duration: 0.2s;
  transition-property: background-color;
`

export const SeeMoreListingsContent = styled(Column as any)`
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100%;
  z-index: 2;
`
export const WhyChooseUsPageSection = styled(PageSection as any)`
  position: relative;
  width: 100%;
  max-width: 1184px;
  transition: 0.3s;
  padding-left: 0;
  padding-right: 0;
`

export const WhyChooseUs = styled(ProgressiveImage)`
  background-color: #fafafa;
  width: 100%;
  background-image:url('${props => props.src}');
  background-size: "cover";
  background-position: "center";
  background-repeat: no-repeat;
`

export const WhyChooseUsContainer = styled.div`
  max-width: 1184px;
  position: relative;
  transition: 0.3s;
  @media (min-width: ${theme.breakpoints[0]}) {
    height: 700px;
  }
`

export const WhyChooseUsPanel = styled(Box as any)`
  position: relative;
  top: 56px;
  left: 0;
  height: auto;
  @media (min-width: ${theme.breakpoints[0]}) {
    top: 80px;
  }
`

export const WhyChooseUsPanelPopup = styled(Row as any)`
  position: relative;
  padding-top: 26px;
  padding-left: 0;
  height: auto;
  transition: 0.3s;
  height: 100%;
  @media (min-width: ${theme.breakpoints[0]}) {
    padding-top: 0;
    padding-left: 29px;
  }
`

export const WhyChooseUsPanelPopupContent = styled(Column as any)`
  height: 100%;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  padding-bottom: 24px;
`
export const WhyChooseUsButton = styled(Button as any)`
  @media (min-width: ${theme.breakpoints[0]}) {
    width: 150px;
  }
`

export const AddButton = styled(Button as any)`
  width: ${props => props.width || 180}px;
  margin-inline: ${props => props.mx || 10}px;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border: ${props => props.border};
  @media (max-width: 768px) {
    margin-block: ${props => (props.margin ? props.margin : "10px")};
    width: ${props => props.maxWidth || "100%"};
    margin-inline: ${props => props.mx || 0}px;
  }
`

export const Messages = styled(Row as any)`
  align-self: flex-start;
`

export const Message = styled(Column as any)`
  align-self: flex-start;
  max-width: 220px;
  padding: 16px;
  border-radius: 14px;
  border-bottom-left-radius: 0;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.008);
  background-color: rgba(60, 66, 64, 0.1);
  @media (min-width: ${theme.breakpoints[0]}) {
    max-width: 450px;
  }
`

export const MessageWhite = styled(Column as any)`
  align-self: flex-end;
  max-width: 220px;
  padding: 16px;
  border-radius: 14px;
  border-bottom-right-radius: 0;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: 1px solid rgba(60, 66, 64, 0.1);
`

export const MessagePicture = styled(Column as any) <ColumnProps & { src: string }>`
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-top: auto;
  overflow: hidden; //add with below css MessageImg
`

export const MessageImg = styled(ProgressiveImage)`
  width:40px;
  height:40px;
  background-color: rgba(60, 66, 64, 0.1);
  background-size:cover;
  background-position:center;
  background-image:url('${props => props.src}');
`
export const Heading3 = styled.h3`
  margin-top: 8px;
  margin-bottom: 0px;
`

export const SignUpToday = styled(ProgressiveImage)`  
  margin-top: 128px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  height: 506px;
  background-size: cover;
  background-position: center;
  background-image: url('${props => props.src}');
`

export const RentalMadeEasy = styled(ProgressiveImage)`
  align-items: center;
  width: 100%;
  height: 688px;
  background-color: #ccc;
  background-size: cover;
  background-position: center;
  background-image: url url('${props => props.src}');
`

export const RentalMadeEasyPanel = styled(Column as any)`
  max-width: 548px;
  opacity: 0.95;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17), 0 2px 20px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
`

export const StyledLink = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  word-break: break-all;
`

export const MobileSelect = styled(Select as any)`
  @media (min-width: ${theme.breakpoints[0]}) {
    display: none;
  }
`

export const DesktopRow = styled(Row as any)`
  @media (max-width: ${theme.breakpoints[0]}) {
    display: none;
  }
`
export const TextWithEllipsis = styled(Text as any)`
  &:empty {
    display: none;
  }
`
export const MissionListItems = [
  {
    icon: Image1,
    title: "Find",
    description: `Explore a diverse range of apartment rentals in Canada and find the best rental option so you can move into the perfect home.`
  },
  {
    icon: Image3,
    title: "Complete",
    description:
      "Start and complete a hassle-free process that takes you from searching to screening to signing, all on Tolobi’s simple and innovative platform."
  },
  {
    icon: Image2,
    title: "Connect",
    description:
      "Make the best application with ease and connect with landlords and rental agents to secure your apartment rentals in Canada."
  }
]

export const LandLordWhyChooseUsTexts = [
  {
    title: "Effective exposure",
    paragraph:
      "Pressed to fill a unit? Easily post a listing that stand outs and gain the right amount of exposure to attract your ideal tenant."
  },
  {
    title: "Visible tenant community",
    paragraph:
      "Tired of receiving the typical “Hi, is this property available?” from strangers? Know exactly who is messaging you with our Tenant Profiles for more effective communication."
  },
  {
    title: "Comprehensive tenant screening",
    paragraph:
      "Protect your investment. Leverage our automated, intelligent screening solutions and credit checks to effectively evaluate your prospective tenants."
  },
  {
    title: "Simpler and faster",
    paragraph:
      "Swamped by the back-and-forth? Securely draft, receive, and sign your lease, all online through Tolobi’s simple and innovative platform."
  },
  {
    title: "In-house agents",
    paragraph:
      "Our rental assistance offers landlords and listing agents a completely hands-off approach for securing the highest-quality tenants for your apartment rentals in Canada."
  },
  {
    title: "Customer support",
    paragraph:
      "Looking for support? Get in touch and get help fast so you can make the perfect listing without breaking a sweat."
  }
]

export const TenantWhyChooseUsTexts = [
  {
    title: "Roommate connection",
    paragraph:
      "Want to split a place? Find potential roommates who are also interested in apartment rentals in Canada.Connect, and then apply together."
  },
  {
    title: "Profile showcasing",
    paragraph:
      "Want to stand out from the competition? Make your Tenant Profile the best of you and showcase to landlords and potential roommates what makes you the perfect tenant."
  },
  {
    title: "Simpler and faster",
    paragraph:
      "Frustrated with everything else? Easily track your communication in one place, pass tenant screening, and maintain leases all on our platform."
  },
  {
    title: "Online transactions",
    paragraph:
      "Securely draft, submit and sign Ontario’s Standard Form of Lease online using trusted service providers."
  },
  {
    title: "Customer support",
    paragraph:
      "Need a hand? Get in touch and receive quick responses! Tolobi also empowers you to make the right choices with our tips and advice for the rental market, along with a comprehensive help centre."
  }
]
